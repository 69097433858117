import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase";
import "firebase/auth";
import axios from "axios";

Vue.use(VueRouter);
const isLoggedIn = () => firebase.auth().currentUser;

const routes = [
  {
    path: "/",
    name: "LoginPagina",
    component: () =>
      import(/* webpackChunkName: "Home" */ "../views/login/LoginPagina.vue"),
    meta: {
      showHeader: false,
      title: "Integratie partners | Dashboard ",
    },
  },

  //Dashboard

  {
    path: "/Dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "MainDisplay" */ "@/views/dashboard/Dashboard.vue"
      ),
    meta: {
      accessRights: ["WERKNEMER"],
      requiresAuth: true,
      title: "Integratie partners | Dashboard ",
    },
  },

  {
    path: "/QRgenerator",
    name: "QRgenerator",
    component: () =>
      import(
        /* webpackChunkName: "MainDisplay" */ "@/views/dashboard/QRgenerator.vue"
      ),
    meta: {
      accessRights: ["WERKNEMER"],
      requiresAuth: true,
      title: "Integratie partners | QRgenerator ",
    },
  },
  {
    path: "/Content",
    name: "Content",
    component: () =>
      import(
        /* webpackChunkName: "MainDisplay" */ "@/views/dashboard/Content.vue"
      ),
    meta: {
      accessRights: ["WERKNEMER"],
      requiresAuth: true,
      title: "Integratie partners | Handelaarsoverzicht ",
    },
  },

  {
    path: "/Teambeheren",
    name: "Teambeheren",
    component: () =>
      import(
        /* webpackChunkName: "MainDisplay" */ "@/views/dashboard/Teambeheren.vue"
      ),
    meta: {
      accessRights: ["WERKNEMER"],
     requiresAuth: true,
      title: "Integratie partners | Handelaarsoverzicht ",
    },
  },

 

  {
    path: "*",
    name: "/",
    component: () =>
      import(
        /* webpackChunkName: "NotFound" */ "../views/login/LoginPagina.vue"
      ),
    meta: { title: " Integratiepartners| Oeps pagina niet gevonden" },
  },
];


export async function setFirebaseToken() {
  const token = (await isLoggedIn())
    ? await firebase.auth().currentUser.getIdToken(true)
    : null;
  if (token) axios.defaults.headers.common["fireToken"] = token;
}

async function onAuthRequired(to, from, next) {
  document.title = to.meta.title || "Waldent | Tandartspraktijk Walden";
  if (!to.meta.accessRights && to.path !== "/") return next("/");
  await setFirebaseToken();
  if (to.meta.requiresAuth) {
    if (!(await isLoggedIn())) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      const response = await axios.get("https://digitalsignbackend.waldent.nl/tas/api/whoami");
      const accessRight = response.data.accessRight;
      if (!to.meta.accessRights.includes(accessRight)) return next("/")
    }
  }
  next();
}

const router = new VueRouter({
  mode: "history",
  //Dit voor weer naar begin
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(onAuthRequired);
export default router;
